.flex {
    display: flex;

    & .flex-center {
        justify-content: center;
        align-items: center;
    }

    & .flex-start {
        justify-content: flex-start;
        align-items: flex-start;
    }

    & .flex-end {
        justify-content: flex-end;
        align-items: flex-end;
    }

    & .flex-1 {
        flex: 1;
    }

    & .flex-2 {
        flex: 1;
    }

    & .flex-row {
        flex-direction: row;
    }

    & .flex-col {
        flex-direction: column;
    }

    & .flex-space-between {
        justify-content: space-between !important;
    }

    & .full-w {
        width: 100%;
    }

    & .flex-j-center {
        justify-content: center;
    }
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-col-nr {
    flex-direction: column;
}

.m-top-100 {
    margin-top: 150px;
}

@media only screen and (max-width: 600px) {

    .flex {
        & .flex-row {
            flex-direction: column;
        }

        & .flex-col {
            flex-direction: row;
        }
    }

    .flex-row {
        flex-direction: column;
    }

    .flex-col-rev {
        flex-direction: column-reverse;
    }

    .m-top-100 {
        margin-top: 100px;
    }
}