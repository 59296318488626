@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;900&display=swap');
@import url('https://use.typekit.net/qqm5fvc.css');


$primary:#FFCE00;
$offWhite:#020202;
$black:#fff;

body {
    font-family: 'Poppins', sans-serif;
    background-color: #000;
}

* {
    margin: 0;
    padding: 0;
}

#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
    background-color: $primary;
}

#fp-nav ul li,
.fp-slidesNav ul li {
    margin: 10px 20px !important;
}

@media only screen and (max-width: 600px) {

    #fp-nav ul li,
    .fp-slidesNav ul li {
        margin: 2px 5px !important;
    }

    #fp-nav.fp-right {
        right: 5px !important;
    }

    #fp-nav ul li:hover a.active span,
    #fp-nav ul li a.active span,
    .fp-slidesNav ul li:hover a.active span,
    .fp-slidesNav ul li a.active span {
        height: 8px !important;
        margin: -4px 0 0 -4px !important;
        width: 8px !important;
    }

}

.logo_container {
    position: relative;

    & .home_border {
        z-index: 999;

        & .left,
        .right,
        .top,
        .bottom {
            position: fixed;
            background-color: #fff;
            z-index: 999;
        }

        & .left,
        .right {
            top: 0;
            bottom: 0;
            width: 0px;
            transition: width 0.2s ease-in-out;
        }

        & .left {
            left: 0;
        }

        & .right {
            right: 0;
        }

        & .top,
        .bottom {
            left: 0;
            right: 0;
            transition: height 0.2s ease-in-out;
            height: 0px;
        }

        & .top {
            top: 0;
        }

        & .bottom {
            bottom: 0;
        }
    }

    & .logo {
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50px;
        z-index: 1000;
        padding: 10px;
        width: 150px;
        opacity: 0;
        transition: opacity 0.2s ease-in;
    }

    & .hide {
        display: none !important;
    }

    & .white {

        & .cls-1 {
            fill: #fff !important;
        }

        & .cls-2 {
            fill: #ffce00;
        }
    }

    & .logo:hover+.home_border {

        & .left,
        .right {
            width: 40px;
        }

        & .top,
        .bottom {
            height: 60px;
        }
    }

    & #logoin {
        opacity: 1;
    }

    & #logoout {
        opacity: 0;
    }

}

@media only screen and (max-width: 600px) {
    .logo_container {
        & .logo {
            width: 150px;

            &-black {
                background-color: #fff;
                border-radius: 4px;
            }
        }
    }
}


#slides {
    & .content_holder {
        height: 100%;
        width: 100%;
        position: relative;

        & h2 {
            color: $primary;
            text-transform: uppercase;
        }

        & .section_text {
            position: absolute;
            top: 45%;
            left: 190px;
            max-width: 400px;
            line-height: 26px;
            opacity: 0;
            transition: opacity 0.3s ease-in;

            & h2 {
                margin: 15px 0;
            }

            & p {
                line-height: 20px;
            }

            & ul {
                list-style: none;

                & li {
                    text-transform: uppercase;
                    color: $primary;
                    font-size: 1em;
                    margin: 16px 0;
                }
            }

            & .social_icons {
                display: flex;

                & .Icon {
                    width: 30px;
                    margin-right: 20px;
                    margin-top: 15px;

                    & .cls-1 {
                        fill: #fff;
                    }
                }
            }
        }

        & #textin {
            opacity: 1;
        }

        & #textout {
            opacity: 0;
        }

    }

    & .full {
        height: 100vh;
        width: 100vw;
    }

    // & .white {
    //     background-color: #fff;
    // }

    & .offwhite {
        background-color: $offWhite;
        color: #fff;
    }

    & .yellow {
        background-color: $primary;
    }

    & .black {
        background-color: #000;
        color: #fff;
    }

    & #intro {

        & .container {
            position: relative;
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            & .maillink {
                position: absolute;
                left: -90px;
                top: 50%;
                -ms-transform: rotate(-90deg);
                /* IE 9 */
                transform: rotate(-90deg);
                // transform: translate(-50%, -50%);
                color: #fff;
                text-decoration: none;
            }

            & .container--inner {
                position: relative;
                overflow: hidden;
                width: 600px;
                height: auto;
                margin: auto;

                & .show {
                    & .cls-1 {
                        fill: #fff;
                    }

                    & .cls-2 {
                        fill: #fff;
                    }

                    & .cls-3 {
                        fill: #fff;
                    }

                    & .cls-4 {
                        fill: #fff;
                    }

                    & .cls-5 {
                        fill: #fff;
                    }

                    & .cls-6 {
                        fill: #fff;
                    }

                    & .cls-7 {
                        fill: #fff;
                    }
                }

                & .video {
                    position: absolute;
                    width: auto;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

            & .icon {
                margin: 0 10px;
            }

            & .scroll_indicator {
                position: absolute;
                bottom: 30px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-items: center;

                & .scroll_indicator_bar {
                    height: 80px;
                    width: 1.5px;
                    background-color: $primary;
                }

                & .scroll_mushroom {
                    margin-top: 10px;
                    height: 20px;


                    -ms-transform: rotateX(180deg);
                    -webkit-transform: rotateX(180deg);
                    -moz-transform: rotateX(180deg);
                    -o-transform: rotateX(180deg);
                    transform: rotateX(180deg);
                }
            }
        }

        & h1 {
            font-size: 6em;
            font-weight: bold;
            text-transform: uppercase;

            &.hero_eng {
                display: block;
            }

        }


    }

    & #team {
        text-align: center;
        margin-top: 140px;

        & .team-member {
            max-width: 350px;
            opacity: 0;
            transition: opacity 0.3s ease-in;

            & .team-member-image {
                height: 150px;
                width: 150px;
            }

            & h2 {
                line-height: 26px;
                margin: 15px 0;
                font-family: 'copal-std-solid', sans-serif;
                font-weight: 400;
                font-style: normal;
                display: flex;
                justify-content: center;
                align-items: center;

                & .linkedInIcon {
                    fill: #fff;
                    width: 20px;
                    margin-left: 10px;

                    & .cls-1 {
                        fill: #fff;
                    }
                }
            }

            & p {
                line-height: 20px;
            }
        }

        & #textin {
            opacity: 1;
        }

        & #textout {
            opacity: 0;
        }
    }
}

.slide {
    background-color: $primary;
    position: relative;

    & .content_holder {
        & .logo_last {
            width: 150px;
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            opacity: 0;
            padding: 10px;
            transition: opacity 0.2s ease-in;

        }

        & #logoin {
            opacity: 1;
        }

        & #logoout {
            opacity: 0;
        }

        height: 100vh !important;
    }

    & .footer {
        height: 350px;
        padding: 0;
        background-color: $offWhite;

        & .fav_footer {
            width: 200px;
            margin: 10px 0;
        }

        & .logo_footer {
            // max-width: 150px;
            width: 200px;
            margin: 10px auto;
        }

        & a {
            color: #fff;
            text-decoration: none;
        }

        & .footer_text {
            padding: 10px;
            width: 330px;
            margin: auto;

            & p {
                margin: 10px 0;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .slide {

        & .footer {
            height: 100%;
            padding: 30px 0;

            & .fav_footer {
                width: 80px;
                color: $primary;
            }

            & .logo_footer {
                width: 100px;
                margin: 10px auto;
            }

            & .footer_text {
                width: 350px;
                text-align: center;
                margin: auto;

                & p {
                    margin: 10px 0;
                }
            }
        }
    }
}

.footer {
    background-color: $black;
    color: #fff;
}

.page-number {
    position: fixed;
    top: 35%;
    left: -300px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: left 0.2s ease-in-out;

    & hr {
        margin: 0px 10px;
        width: 80px;
        height: 2px;
        border: 0px solid #fff;
        background-color: $black;
    }

    & h1 {
        color: $primary;
        font-family: Arial, Helvetica, sans-serif;

        &.title {
            font-family: 'copal-std-solid', sans-serif;
            font-weight: 400;
            font-style: normal;
            color: $black;
            font-size: 1.8em;
            display: flex;
            justify-items: flex-end;
            align-items: flex-end;
            text-transform: uppercase;

            & .icon {
                margin: 5px 8px;
                padding: 0;
                width: 10px !important;
            }
        }

        &.white {
            color: #fff;
        }
    }

    &-black {
        color: #fff;

        & hr {
            background-color: #fff;
        }
    }
}

.hide_page_number {
    display: none;
}

.page-number-last {
    position: absolute !important;
    height: auto;
    top: 35%;
    left: -300px;
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: left 0.2s ease-in-out;
    overflow: auto;

    & hr {
        margin: 0px 10px;
        width: 80px;
        height: 2px;
        border: 0px solid #fff;
        // background-color: $black;
    }

    & h1 {
        color: $primary;
        font-family: Arial, Helvetica, sans-serif;

        &.title {
            font-family: 'copal-std-solid', sans-serif;
            font-weight: 400;
            font-style: normal;
            color: $black;
            // background-color: $offWhite;
            font-size: 1.8em;
            display: flex;
            justify-items: flex-end;
            align-items: flex-end;
            text-transform: uppercase;

            & .icon {
                margin: 0px 8px;
                padding: 0;
                width: 10px !important;
            }
        }

    }
}

@media only screen and (max-width: 600px) {
    .page-number-last {
        top: 120px;
        display: inline;
        margin-left: 30px;
        // background-color: pink;

        & hr {
            margin: 0px;
            padding: 0;
            width: 0px;
        }

        & h1 {
            font-size: 1.3em;

            &.title {
                margin: 0px;
                padding: 0;
                font-size: 1.3em;
                display: flex;
                justify-items: flex-end;
                align-items: flex-end;
                text-transform: uppercase;

                & .icon {
                    margin: 0px 8px;
                    padding: 0;
                    width: 10px !important;
                }
            }
        }

        &-black {
            color: #fff;

            & hr {
                background-color: #fff;
            }
        }

    }
}

.hide-responsive {
    display: flex;
}

#in {
    left: 60px;
}

#out {
    left: -340px;
}

@media only screen and (max-width: 600px) {
    .page-number {
        top: 120px;
        display: inline;
        margin-left: 30px;
        // background-color: pink;

        & hr {
            margin: 0px;
            padding: 0;
            width: 0px;
        }

        & h1 {
            font-size: 1.3em;

            &.title {
                margin: 0px;
                padding: 0;
                font-size: 1.3em;
                display: flex;
                justify-items: flex-end;
                align-items: flex-end;
                text-transform: uppercase;

                & .icon {
                    margin: 0px 8px;
                    padding: 0;
                    width: 10px !important;
                }
            }
        }

        &-black {
            color: #fff;

            & hr {
                background-color: #fff;
            }
        }

    }

    .hide-responsive {
        display: none;
    }


    #in {
        left: 20px;
    }

    #out {
        left: -290px;
    }


    .hide_page_number {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    #slides {
        & #intro {
            & .container {

                & .maillink {
                    position: absolute;
                    left: 50%;
                    top: 56%;
                    -ms-transform: rotate(-0deg);
                    /* IE 9 */
                    transform: rotate(-0deg);
                    transform: translate(-50%, -50%);
                    color: #fff;
                    text-decoration: none;
                }

                & .container--inner {
                    position: relative;
                    overflow: hidden;
                    width: 260px;
                    height: auto;
                    margin: auto;

                    & .show {
                        & .cls-1 {
                            fill: #fff;
                        }

                        & .cls-2 {
                            fill: #fff;
                        }

                        & .cls-3 {
                            fill: #fff;
                        }

                        & .cls-4 {
                            fill: #fff;
                        }

                        & .cls-5 {
                            fill: #fff;
                        }

                        & .cls-6 {
                            fill: #fff;
                        }

                        & .cls-7 {
                            fill: #fff;
                        }
                    }

                    & .video {
                        position: absolute;
                        width: auto;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }

            }

            & h1 {
                font-size: 3em !important;
            }
        }

        & .content_holder {
            & .section_text {
                position: absolute;
                top: 200px;
                left: 50px;
                max-width: 250px;
                line-height: 26px;

                & h2 {
                    font-size: 1.2em;
                    margin: 15px 0;
                }

                & p {
                    font-size: 1em;
                    line-height: 18px;
                }

                & ul {
                    list-style: none;

                    & li {
                        text-transform: uppercase;
                        color: $primary;
                        font-size: 0.7em;
                        margin: 5px 0;
                    }
                }
            }
        }

        & #team {
            text-align: center;
            margin-top: 0px;

            & .team-member {
                max-width: 300px;

                & .team-member-image {
                    height: 150px;
                    width: 150px;
                    margin-bottom: 10px;
                    padding: 0;
                }

                & h2 {
                    margin: 0;
                    padding: 0;
                    line-height: 26px;
                    margin-bottom: 10px;
                }

                & p {
                    margin: 0;
                    padding: 0;
                    line-height: 20px;
                }
            }
        }
    }
}